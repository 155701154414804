/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import React, { useEffect } from 'react';
import { StyledSelect as Select } from './styled-components';
// import useDebounce from '../../../hooks/useDebounce';
import TextField from '../CustomTextField';
import { useField, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/styles';

const CustomFormHelperText = withStyles((theme) => ({
  root: {
    color: 'var(--colors-secondary)',
    fontSize: '0.9rem',
    fontStyle: 'italic',
  },
}))(FormHelperText);

function TextOrSelect({
  select,
  options,
  label,
  customClass,
  dependentField,
  dependency,
  changeNotifier,
  helperText = '    ',
  ...props
}) {
  const { values, touched, dirty } = useFormikContext();
  const [field, meta, helper] = useField(props);
  const debouncedValue = values[dependentField]; //useDebounce(values[dependentField], 500);
  useEffect(() => {
    if (dependentField) {
      const dependencyValue = values[dependentField];
      if ((touched[dependentField] || dirty) && dependency && (dependencyValue || dependencyValue === 0)) {
        const calculatedValue = dependency(dependencyValue);
        helper.setValue(Number.isSafeInteger(calculatedValue) ? calculatedValue : calculatedValue || '');
      } else {
        helper.setValue('');
      }
    }
  }, [debouncedValue]);

  return !select ? (
    <FormControl className={customClass} error={Boolean(meta.touched && meta.error)}>
      <TextField label={label} {...field} {...props} />
      <CustomFormHelperText>{Boolean(meta.touched && meta.error) ? meta.error : helperText}</CustomFormHelperText>
    </FormControl>
  ) : (
    <FormControl className={customClass} error={Boolean(meta.touched && meta.error)}>
      <InputLabel style={{ color: 'var(--colors-contrast-text-primary)' }} id={`${label}-id-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-id-label`}
        id={`${label}-id-label-select`}
        {...field}
        {...props}
        onChange={(e) => {
          if (['click', 'keydown'].includes(e.type) && Boolean(changeNotifier)) {
            changeNotifier(e.target.value);
          }
          field.onChange(e);
        }}
      >
        {options?.map(({ label, value }, index) => {
          return (
            <MenuItem value={value} key={index}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      <CustomFormHelperText>{Boolean(meta.touched && meta.error) ? meta.error : helperText}</CustomFormHelperText>
    </FormControl>
  );
}

export default TextOrSelect;
