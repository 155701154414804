/**
 * Use when you need to show loader inside the button
 */
import React from "react";
import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const WithOpacity = styled.span`
  opacity: ${(props) => (props.transparent ? 0 : 1)};
`;
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(
  spacing,
  () => `
  position: absolute;
`
);

export default function LoadingButton({
  isLoading,
  children,
  color = "primary",
  disabled,
  ...rest
}) {
  return (
    <Button
      size="small"
      variant="contained"
      color={color}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading ? <CircularProgress size={22} p={1} color="inherit" /> : null}
      <WithOpacity transparent={isLoading ? "transparent" : null}>
        {children}
      </WithOpacity>
    </Button>
  );
}
