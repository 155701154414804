/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { CssBaseline, Button, TextField, Box } from '@material-ui/core';
import Layout from '../../Components/OnboardingLayout';
import OnboardingHeader from '../../Components/Onboarding/OnboardingHeader';
import { Formik, Form } from 'formik';
import TextOrSelect from '../../Components/Forms/TextOrSelect';
import { CustomBtn } from '../../Components/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import './onboardingView.css';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../routes/constants';
import { BrandCreationValidationSchema } from '../../Constants/constants';
import { useAxios } from '../../../hooks/useAxios';
import {
  COUNTRIES_URL,
  ONBOARDING_GET_BRAND_NAME,
  ONBOARD_CREATE_BRAND,
  VOUCHER_VALIDATION_URL,
} from '../../../api/api';
import { getOnlyCurrencySymbol } from '../../../helpers/currencyHelper';
import { handleAction, messageContainer } from '../../../helpers/other';
import { useQuery } from '../../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { REFERER } from './const';
import SimpleBackdrop from '../../../Components/BackDrop';
import SimpleDialog from '../../../Components/SimpleDialog';
import { Autocomplete } from '@material-ui/lab';
import { loadUser, updateUser } from '../../../redux/auth/actions';
import { showNotification } from '../../../redux/notifications/actions';
import { VOUCHER } from '../../../constants/extras';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.body.login?.layoutBg};
    transition: background 0.3s;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-option {
    color: var(--colors-contrast-text-seconday);
  }
  .MuiAutocomplete-inputRoot {
    color: var(--colors-contrast-text-seconday);
  }
  .MuiAutocomplete-clearIndicator {
    color: var(--colors-contrast-text-seconday);
  }
  .MuiAutocomplete-popupIndicator {
    color: var(--colors-contrast-text-seconday);
  }
`;
const CustomButton = styled(Button)`
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  border: 0.1rem solid var(--colors-primary);
  background-color: var(--colors-secondary-hover);
  :disabled {
    background-color: var(--color-hover-charcol) !important;
    color: var(--colors-contrast-text-primary) !important;
  }
  :hover {
    background-color: var(--colors-primary);
    color: var(--colors-contrast-text-primary);
    border: 0.1rem solid var(--colors-secondary);
  }
`;

const AvailableBrandDialog = ({ brands = [], seletedAvailableBrand, setSeletedAvilableBrnd }) => {
  return (
    <div>
      <StyledAutocomplete
        id="brand-selection"
        options={brands}
        getOptionLabel={(option) => option.name || ''}
        onChange={(e, newVal) => {
          if (newVal && newVal.name) {
            setSeletedAvilableBrnd(newVal.id);
          } else {
            setSeletedAvilableBrnd(0);
          }
        }}
        value={seletedAvailableBrand ? brands.find((b) => b.id === seletedAvailableBrand) : ''}
        renderInput={(params) => <TextField {...params} label="Brands" style={{ width: '50%' }} />}
      />
    </div>
  );
};

function BaseBrandCreationComponet() {
  const [showExistingBrand, setShowExistingBrand] = useState(false);
  const [selectedAvailableBrand, setSelectedAvailableBrand] = useState(0);
  const [updateSelectedBrandInProgess, setUpdateSelectedBrandInProgess] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [isVoucherCodeValid, setIsVoucherCodeValid] = useState(false);
  const [isVoucherCodeError, setisVoucherCodeError] = useState(false);
  const [continueButtonDisable, setContinueButtonDisable] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const voucherError = () => voucher && voucher.trim().length < 6;

  const [initialValue, setIntialValue] = useState({
    brand_name: '',
    default_currency: '',
    voucher_code: '',
  });
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { onboradingrequest, refer } = useQuery();
  const { data: countries } = useAxios({ url: COUNTRIES_URL, showError: true });
  const { baseAxiosCall: createBrand } = useAxios({
    url: ONBOARD_CREATE_BRAND,
    method: 'post',
    callOnMount: false,
    showError: true,
  });
  const {
    data: brandName,
    error: brandNameError,
    isLoading: brandNameLoading,
    callAxios: getBrandName,
  } = useAxios({ url: ONBOARDING_GET_BRAND_NAME, callOnMount: false, showError: true });

  useEffect(() => {
    if (refer && [(REFERER.SHOPIFY, REFERER.AMAZON)].includes(refer)) {
      if (user && user.id) {
        getBrandName({ params: { onboarding_request: onboradingrequest } });
      }
    }
  }, [refer]);

  useEffect(() => {
    if (brandNameError) return;
    if (brandName) {
      if (brandName.brand_name) {
        setIntialValue((v) => ({ ...v, brand_name: brandName.brand_name }));
      }
    }
  }, [brandName, brandNameError]);

  useEffect(() => {
    if (countries && Array.isArray(countries) && countries.length > 0) {
      const countriesData = countries.map((details) => {
        const symbol = getOnlyCurrencySymbol(details.default_currency);
        return { value: details.default_currency, label: `${details.country_name} - (${symbol})` };
      });
      setCurrency(countriesData);
    }
  }, [countries]);

  const { isLoading: isVoucherLoading, callAxios: callVoucherApi } = useAxios({
    callOnMount: false,
    onError: () => setisVoucherCodeError(true),
    url: VOUCHER_VALIDATION_URL.replace('<voucher_code>', voucher).replace('<currency>', selectedCurrency),
    onSuccess: () => {
      setisVoucherCodeError(false);
      setIsVoucherCodeValid(true);
      setContinueButtonDisable(false);
    },
  });

  const voucherCodeHelperText = () => {
    return (
      <>
        {voucherError() && messageContainer(VOUCHER.min_char_limit, 'error')}
        {isVoucherCodeError
          ? messageContainer(VOUCHER.invalid_voucher, 'error')
          : isVoucherCodeValid && messageContainer(VOUCHER.valid_voucher, 'secondary')}
        {!Boolean(selectedCurrency) && voucher?.length && messageContainer(VOUCHER.select_currency, 'error')}
        {messageContainer(VOUCHER.credit_days, 'secondary')}
      </>
    );
  };

  const createBrandSubmitHandler = async (
    { brand_name, default_currency },
    { setSubmitting, validateForm, setFieldError }
  ) => {
    setSubmitting(true);
    let createBrandData = { brand_name, default_currency, voucher_code: voucher };
    if (onboradingrequest) {
      createBrandData = { onboarding_request_id: onboradingrequest, ...createBrandData };
    }
    try {
      const brand = await createBrand({ data: createBrandData });
      if (brand) {
        const { message, request_id = '' } = brand;
        if (message && message.toLowerCase() === 'success') {
          history.push({
            pathname: paths.AUTH_SIGNUP_BRAND_MAPPING,
            search: new URLSearchParams({ onboradingrequest: request_id, refer: refer || 'none' }).toString(),
          });
        }
        setSubmitting(false);
      }
    } catch (e) {
      const { status = '', data = '' } = e?.response || {};
      if (status && data) {
        const errorMessage = `${data.reason}`;
        setFieldError('brand_name', errorMessage);
      }
      setSubmitting(false);
    }
  };

  const linkExistingBrand = () => {
    if (selectedAvailableBrand) {
      setUpdateSelectedBrandInProgess(true);
      dispatch(updateUser('brand', selectedAvailableBrand))
        .then((action) => {
          handleAction({
            action,
            callback: ({ response }) => {
              console.log('Update User Api call');
              if ((response.current_brand.id = selectedAvailableBrand)) {
                dispatch(loadUser());
                history.push({
                  pathname: paths.AUTH_SIGNUP_BRAND_MAPPING,
                  search: new URLSearchParams({ onboradingrequest, refer: refer || 'none' }).toString(),
                });
              }
            },
            errorHandler: () => {
              const message = typeof action?.error?.data?.reason === 'string' ? action?.error?.data?.reason : '';
              dispatch(showNotification(message, 'error'));
            },
          });
        })
        .finally(() => {
          setUpdateSelectedBrandInProgess(false);
        });
    }
  };

  return (
    <>
      <SimpleBackdrop show={brandNameLoading} />
      <OnboardingHeader step="1" subheader="Fill in the details of your brand" />
      <Formik
        initialValues={initialValue}
        onSubmit={createBrandSubmitHandler}
        validationSchema={BrandCreationValidationSchema}
        enableReinitialize="true"
      >
        {(formik) => (
          <Form>
            <div className="brand-create-container">
              <TextOrSelect name="brand_name" label="Registered brand name" />
              <TextOrSelect
                select={currency.length > 0}
                options={currency}
                name="default_currency"
                label="Default currency"
                changeNotifier={(curr) => setSelectedCurrency(curr)}
              />
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextOrSelect
                  name="voucher_code"
                  label="Voucher Code"
                  value={voucher}
                  helperText={voucherCodeHelperText()}
                  onChange={(e) => {
                    setVoucher(e.target.value);
                    setIsVoucherCodeValid(false);
                  }}
                />
                <CustomButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  disabled={isVoucherLoading || voucherError() || !Boolean(selectedCurrency)}
                  onClick={() => voucher?.length && callVoucherApi()}
                >
                  Validate
                </CustomButton>
              </Box>
              <CustomBtn
                size="small"
                type="submit"
                variant="contained"
                disabled={
                  formik.isValidating ||
                  formik.isSubmitting ||
                  continueButtonDisable ||
                  (voucher?.length && !isVoucherCodeValid)
                }
                style={{ alignSelf: 'center', width: '50%' }}
              >
                {formik.isSubmitting ? <CircularProgress style={{ color: 'var(--colors-secondary)' }} /> : 'Continue'}
              </CustomBtn>
            </div>
          </Form>
        )}
      </Formik>
      {user && user.available_brands && user.available_brands.length > 0 && (
        <>
          <Button
            style={{
              fontWeight: 'bolder',
              alignSelf: 'center',
              width: '50%',
              fontSize: '1rem',
              color: 'var(--colors-secondary)',
            }}
            onClick={(e) => {
              setShowExistingBrand(true);
            }}
          >
            Link with an existing brand
          </Button>
          <SimpleDialog
            maxWidth="sm"
            subtitle="Select a brand from dropdown and click on Link Brand"
            closeIcon={true}
            actionsSaveDisabled={!selectedAvailableBrand}
            isSaving={updateSelectedBrandInProgess}
            cancelButtonVariant="outlined"
            saveButtonColor="primary"
            open={showExistingBrand}
            title="Select Brand"
            saveText="Link Brand"
            onSave={linkExistingBrand}
            onCancel={() => {
              setShowExistingBrand(false);
            }}
            footerButtonRight={false}
          >
            <AvailableBrandDialog
              brands={user.available_brands || []}
              seletedAvailableBrand={selectedAvailableBrand}
              setSeletedAvilableBrnd={setSelectedAvailableBrand}
            />
          </SimpleDialog>
        </>
      )}
    </>
  );
}

function BrandCreation() {
  return (
    <Root>
      <GlobalStyle />
      <CssBaseline />
      <Layout content={<BaseBrandCreationComponet />} />
    </Root>
  );
}

export default BrandCreation;
