import React from 'react';
import axios from '../api/HttpService';
import { useMounted } from './useMounted';
import { getRequestHeaders } from '../helpers/other';
import { useDispatch } from 'react-redux';
import { showNotification } from '../redux/notifications/actions';
import { notifications } from '../constants';

/**
 *
 * @param url
 * @param method
 * @param configData
 * data provided right to the axios config. Can be redefined by config provided right to the returned callAxios function.
 * @param callOnMount
 * call callAxios right on mount or not. By default is true. Useful when you have to get some date only
 * once, on mount. Set to false if you use it, for example, in listings, when you have to pass some additional params
 * every time you change some variables (page, rowsPerPage, search, filters, etc.).
 * @return {{isLoading: boolean, loaded: boolean, data: unknown(response.data), callAxios: callAxios, cancelRequest: Canceler, error: unknown}}
 * isLoading -> is loading
 * loaded -> will be true after first response
 * data -> response.data
 * error -> handled error
 * callAxios -> function to call api once again, accepts useConfig params which goes right to the axios config.
 * cancelRequest -> function to call if you need to cancel request for some reason. Cancel is handled in .catch block
 * */
const defaultError = notifications.ERROR.something_wrong;

export const useAxios = ({
  url = '',
  method = 'get',
  configData = {},
  callOnMount = true,
  onSuccess,
  onError,
  showError = false,
  isPublicApi = false,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(!!callOnMount);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const isMounted = useMounted();

  const cancelTokenSource = axios.CancelToken.source();

  const callAxios = (userConfig) => {
    return new Promise((res, rej) => {
      setIsLoading(true);
      setError(null);
      let config = {
        url,
        method,
        cancelToken: cancelTokenSource.token,
        ...configData,
        ...userConfig,
      };

      if (!isPublicApi) {
        config = {
          ...config,
          headers: getRequestHeaders(),
        };
      }

      axios(config)
        .then(({ data }) => {
          if (isMounted) {
            setData(data);
            setIsLoading(false);
            setLoaded(true);
            if (onSuccess) {
              onSuccess(data);
            }
            res(data);
          }
        })
        .catch((error) => {
          let er;
          if (isMounted) {
            if (axios.isCancel(error)) {
              setIsLoading(false);
            } else {
              if (error.response?.data?.error) {
                er = error.response.data.error;
              } else if (error?.response?.data?.reason) {
                er = error.response.data.reason;
              } else if (error.response?.data?.message) {
                er = error.response?.data?.message;
              } else if (error.response) {
                er = error.response;
              } else if (error.request) {
                er = error.request;
              } else if (error.message) {
                er = error.message;
              } else if (error.data?.error) {
                er = error.data?.error;
              } else {
                er = defaultError;
              }
              if (onError) {
                onError(typeof er === 'string' ? er : defaultError);
              }
              if (showError) {
                dispatch(showNotification(typeof er === 'string' ? er : defaultError, 'error'));
              }
              setError(typeof er === 'string' ? er : defaultError);
              setLoaded(true);
              setIsLoading(false);
            }
          }
        });
    });
  };

  const baseAxiosCall = (userConfig) => {
    return new Promise((res, rej) => {
      setIsLoading(true);
      setError(null);
      let config = {
        url,
        method,
        cancelToken: cancelTokenSource.token,
        ...configData,
        ...userConfig,
      };

      if (!isPublicApi) {
        config = {
          ...config,
          headers: getRequestHeaders(),
        };
      }

      axios(config)
        .then(({ data }) => {
          res(data);
        })
        .catch((error) => {
          rej(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const downloadFile = (userConfig) => {
    return new Promise((res, rej) => {
      setIsLoading(true);
      setError(null);
      const config = {
        url,
        method,
        headers: getRequestHeaders(),
        cancelToken: cancelTokenSource.token,
        ...configData,
        ...userConfig,
        responseType: 'blob',
      };

      axios(config)
        .then(({ data, headers }) => {
          const href = URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = href;
          const contentDisposition = headers['content-disposition'];
          const filename = contentDisposition.substring(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1
          );
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          res(filename);
        })
        .catch((error) => {
          let er;
          if (isMounted) {
            if (axios.isCancel(error)) {
              setIsLoading(false);
            } else {
              if (error.response?.data?.error) {
                er = error.response.data.error;
              } else if (error.response?.data?.message) {
                er = error.response?.data?.message;
              } else if (error.response) {
                er = error.response;
              } else if (error.request) {
                er = error.request;
              } else if (error.message) {
                er = error.message;
              } else if (error.data?.error) {
                er = error.data?.error;
              } else {
                er = defaultError;
              }
              if (onError) {
                onError(typeof er === 'string' ? er : defaultError);
              }
              if (showError) {
                dispatch(showNotification(typeof er === 'string' ? er : defaultError, 'error'));
              }
              setError(typeof er === 'string' ? er : defaultError);
              setLoaded(true);
              setIsLoading(false);
            }
          }
        })
        .finally(() => {
          setLoaded(true);
          setIsLoading(false);
        });
    });
  };

  React.useEffect(() => {
    callOnMount && callAxios();
    //eslint-disable-next-line
  }, [isMounted]);

  const resetAxiosData = () => {
    setData(null);
    setIsLoading(false);
    setLoaded(true);
  };

  return {
    isLoading,
    loaded,
    data,
    error,
    callAxios,
    baseAxiosCall,
    downloadFile,
    resetAxiosData,
    cancelRequest: cancelTokenSource.cancel,
  };
};
