import React from 'react';
import styled from 'styled-components';
import ProductTourLinkButton from './Joyride/components/Onboarding/ProductTourLinkButton';
// import { NavLink as RouterNavLink } from "react-router-dom";

import { Grid, Hidden, ListItemText, ListItem as MuiListItem } from '@material-ui/core';
import { openPrivacy_TnCPage } from '../helpers/Footer';

// const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.background.light};
  position: relative;
  height: 35px;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.common.black};
  }
  cursor: pointer;
`;

function Footer({ isSideNavOpen, user, routes }) {
  return (
    <Wrapper data-testid="footer" style={{ marginLeft: !isSideNavOpen && '4rem' }}>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6} lg={6}>
            <ListItem onClick={() => openPrivacy_TnCPage('Privacy')}>
              {/* <ListItem component={NavLink} to={paths.PRIVACY} exact> */}
              <ListItemText primary="Privacy" />
            </ListItem>
            <ListItem onClick={() => openPrivacy_TnCPage('Terms of Service')}>
              {/* <ListItem component={NavLink} to={paths.TERMS_OF_SERVICE} exact> */}
              <ListItemText primary="Terms of Service" />
            </ListItem>
            <ListItem id="product_tour">
              {/* <ListItem component={NavLink} to={paths.TERMS_OF_SERVICE} exact> */}
              {user?.shipping_enabled && <ProductTourLinkButton {...{ routes }} />}
            </ListItem>

            <ListItem style={{ marginLeft: 'auto' }}>
              <ListItemText primary={`© ${new Date().getFullYear()} - LOCAD`} />
            </ListItem>
          </Grid>
        </Hidden>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
